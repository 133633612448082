<template>
  <div class="box">
    <div class="headline">职能维护</div>
    <div class="content">
      <p>进入职能管理界面，【系统设置】→【职能管理】，如下图</p>
      <img src="../../assets/specificationImgs/img15.png" alt width="100%" />
      <p>页面打开默认显示所有职能</p>
      <br />
      <p>
        1）新增：点击页面右上角即弹出新增弹窗，输入《职能名称》，《职能编号》点击“确定”即可，如下图。
      </p>
      <img src="../../assets/specificationImgs/img27.png" alt width="100%" />
      <br />
      <br />
      <p>
        2）修改：从列表中选中右侧修改按钮，弹出修改弹窗，按需修改员工信息，点击“确定”，如下图。
      </p>
      <img src="../../assets/specificationImgs/img28.png" alt width="100%" />
      <br />
      <p>3）删除：选中对应行，点击右侧“红色垃圾桶”删除。</p>
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>
